<template>
  <!-- 设备管理 —— 车牌识别路牙机 -->
  <div v-loading="loading" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)" class="ParkingInformation">
    <!-- 查询表单 -->
    <el-form :inline="true" size="small" label-position="right" class="query_form" :model="queryForm"
      @submit.native.prevent>
      <el-form-item label="停车场：" v-if="this.getUserInfo.user_name === 'admin'">
        <el-select popper-class="my-select" v-model="queryForm.parking_id" filterable clearable default-first-option
          placeholder="请选择停车场" @change="onSubmit">
          <el-option v-for="item in getAllParkingList" :key="item.parking_id" :label="item.parking_name"
            :value="item.parking_id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="序列号：">
        <el-input v-model="queryForm.device_serial" maxlength="30" @keyup.enter.native="onSubmit"
          @input="(e) => (queryForm.device_serial = validFacility(e))" placeholder="请输入序列号"></el-input>
      </el-form-item>
      <el-form-item label="设备名称：">
        <el-input v-model="queryForm.device_name" maxlength="30" @keyup.enter.native="onSubmit"
          @input="(e) => (queryForm.device_name = validSpace(e))" placeholder="请输入设备名称"></el-input>
      </el-form-item>
      <!-- <el-form-item label="状态：">
            <el-select popper-class="my-select" placeholder="请选择设备状态" clearable v-model="queryForm.device_state" @change="onSubmit" @clear="queryForm.device_state = null">
              <el-option label="全部" :value="0"></el-option>
              <el-option label="在线" :value="1"></el-option>
              <el-option label="离线" :value="2"></el-option>
            </el-select>
          </el-form-item> -->
      <el-form-item>
        <lbButton icon="chaxun" :fill="true" @click="onSubmit()">查询</lbButton>
      </el-form-item>
    </el-form>
    <le-card title="车牌识别路牙机">
      <template slot="heard-right">
        <div>
          <lbButton type="err" icon="shanchu1" @click="batchDel"
            v-if='isShowBtn("00778dfab089450989911d03") || isShowBtn("ad0ecdec7fed4079b8b60dc0")'>删除</lbButton>
          <lbButton icon="xinzeng" @click="openAddForm()"
            v-if='this.getUserInfo.user_name != "admin" && (isShowBtn("2426827c92b34bc5a1a1cca1") || isShowBtn("100e27ffaeb84e54887e6248"))'>
            新增</lbButton>
        </div>
      </template>
      <div class="box-card">
        <!-- 主要内容 -->
        <div style="text-align:left;">
          <!-- 表格 -->
          <!-- 使用插件生产表格 -->
          <!-- <avue-crud :data="tableData" :option="option" :page.sync="page" @selection-change="selectionChange"
            @size-change="sizeChange" @current-change="currentChange">
            <template slot-scope="{row}" slot="parking_name">
              <div :style="row.parking_name != '未配置' ? '' : 'color: #aaa;'">{{ row.parking_name }}</div>
            </template>
            <template slot-scope="{row}" slot="electric_quantity">
              <div :style="row.electric_quantity <= 20 ? 'color: #ff0000;' : 'color: #18943b;'">{{
                row.electric_quantity }}%</div>
            </template>
            <template slot-scope="{row}" slot="create_time">
              {{ $moment(row.create_time).format("YYYY-MM-DD HH:mm:ss") }}
            </template>
            <template slot-scope="{row}" slot="menu">
              <lbButton type="succes" icon="chakan" hint="查看" @click="lookDeviceMachine(row)"></lbButton>
              <lbButton type="warning" icon="bianji" hint="编辑" @click="openEditForm(row)"
                v-if='isShowBtn("a6a27f38140e48e0a4351a91") || isShowBtn("3434c6a7e13e4c7dbf65d91d")'></lbButton>
            </template>
          </avue-crud> -->
          <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" border stripe
            @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="50" align="center" header-align="center"></el-table-column>
            <el-table-column prop="machine_serial" label="序列号"></el-table-column>
            <el-table-column prop="machine_name" label="设备名称"></el-table-column>
            <el-table-column prop="machine_brand_name" label="设备品牌"></el-table-column>
            <el-table-column prop="machine_model_name" label="设备型号"></el-table-column>
            <el-table-column prop="parking_name" label="停车场" v-if="this.getUserInfo.user_name === 'admin'">
              <template slot-scope="scope">
                <div :style="scope.row.parking_name != '未配置' ? '' : 'color: #aaa;'">{{ scope.row.parking_name }}</div>
              </template>
            </el-table-column>
            <el-table-column label="电量" width="100">
              <template slot-scope="scope">
                <div :style="scope.row.electric_quantity <= 20 ? 'color: #ff0000;' : 'color: #18943b;'">{{
                  scope.row.electric_quantity }}%</div>
              </template>
            </el-table-column>
            <el-table-column label="创建时间">
              <template slot-scope="scope">
                {{ $moment(scope.row.create_time).format("YYYY-MM-DD HH:mm:ss") }}
              </template>
            </el-table-column>
            <el-table-column label="操作" width="150">
              <template slot-scope="scope">
                <lbButton type="succes" icon="chakan" hint="查看" @click="lookDeviceMachine(scope.row)"></lbButton>
                <lbButton type="warning" icon="bianji" hint="编辑" @click="openEditForm(scope.row)"
                  v-if='isShowBtn("a6a27f38140e48e0a4351a91") || isShowBtn("3434c6a7e13e4c7dbf65d91d")'></lbButton>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page="queryForm.PageIndex" :page-sizes="[10, 20, 30, 40]" :page-size="queryForm.PageSize"
            layout="total, sizes, prev, pager, next, jumper" :total="total" >
          </el-pagination>
        </div>
      </div>
    </le-card>

    <!-- 新增对话框 -->
    <lebo-dialog append-to-body :title="title" :isShow="isAddDialog" width="30%" @close="closeAddDialogVisible()"
      footerSlot>
      <el-form :model="addForm" :rules="addFormRules" ref="addFormRef" label-width="120px" class="demo-ruleForm">
        <el-form-item label="设备名称：" prop="machine_name">
          <el-input v-model="addForm.machine_name" maxlength="30" placeholder="请输入设备名称"
            @input="(e) => (addForm.machine_name = validSpace(e))"></el-input>
        </el-form-item>
        <el-form-item label="设备品牌：" prop="machine_brand_id">
          <el-select popper-class="my-select" v-model="addForm.machine_brand_id" placeholder="请选择设备品牌"
            @change="brandChange">
            <el-option v-for="(item, index) in brandList" :key="index" :label="item.brand_name"
              :value="item._id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="设备型号：" prop="machine_model_id">
          <el-select popper-class="my-select" v-model="addForm.machine_model_id" placeholder="请选择设备型号"
            @change="modelChange">
            <el-option v-for="(item, index) in modelList" :key="index" :label="item.model_name"
              :value="item._id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="序列号：" prop="machine_serial">
          <el-input v-model="addForm.machine_serial" maxlength="30" placeholder="请输入序列号"
            @input="(e) => (addForm.machine_serial = validFacility(e))"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <!-- <lbButton type="goBack" icon="back" @click="closeAddDialogVisible()">返 回</lbButton> -->
        <lbButton type="default" fill icon="confirm" @click="submitAddForm()" v-preventReClick>保 存</lbButton>
      </span>
    </lebo-dialog>
    <lebo-dialog :isShow="showLookDeviceMachine" width="50%" title="查看设备" :footerSlot="true"
      @close="showLookDeviceMachine = false" closeOnClickModal>
      <lookDeviceMachine :machine_id="dialogMachineId" v-if="showLookDeviceMachine"></lookDeviceMachine>
    </lebo-dialog>
  </div>
</template>

<script>
import { getDeviceMachineList, addDeviceMachine, delDeviceMachine, updateDeviceMachine, getBrandList, getModelList } from '@/api/deviceApi'
import { mapState, mapGetters, mapMutations } from 'vuex'
import lookDeviceMachine from './components/lookDeviceMachine/index.vue'
export default {
  components: { lookDeviceMachine },
  data () {
    return {
      parkingList: [],
      // 查询表单
      queryForm: {
        parking_id: '',
        PageSize: 10,
        PageIndex: 1,
        device_name: '',
        device_serial: '',
        device_state: null
      },
      total: 0,
      // 表格数据
      tableData: [],
      // 选中的表格数据
      multipleSelection: [],
      // 新增对话框
      isAddDialog: false,
      title: '',
      // 设备品牌下拉框
      brandList: [],
      // 设备型号下拉框
      modelList: [],
      // 新增表单
      addForm: {
        _id: '',
        machine_name: '', // 路牙机名称
        machine_brand_id: '', // 路牙机品牌Id
        machine_brand_name: '', // 路牙机品牌名称
        machine_model_id: '', // 路牙机型号id
        machine_model_name: '', // 路牙机型号
        machine_serial: '', // 设备序列号
        machine_model_pic: '' // 硬件图片
      },
      // 表格插件配置项
      option: {
        searchMenuSpan: 8,
        header: false,
        delBtn: false, // 行删除按钮
        editBtn: false, // 编辑按钮
        selection: true,
        selectionFixed: false, // 解除选择框冻结
        menuAlign: 'left', // 属性对齐方式
        menuHeaderAlign: 'left', // 表头对齐方式
        menu: true,
        menuFixed: false, // 解除操作框冻结
        tip: false,
        column: [
          {
            label: '序列号',
            prop: 'machine_serial'
          }, {
            label: '设备名称',
            prop: 'machine_name'
          }, {
            label: '设备品牌',
            prop: 'machine_brand_name'
          }, {
            label: '设备型号',
            prop: 'machine_model_name'
          }, {
            label: '停车场',
            prop: 'parking_name',
            slot: true
          }, {
            label: '电量',
            prop: 'electric_quantity',
            slot: true
          }, {
            label: '创建时间',
            prop: 'create_time',
            slot: true
          }
        ]
      },
      // 分页器
      page: {
        background: false,
        total: 0,
        pageSize: 10,
        currentPage: 1
      },
      // 新增表单验证
      addFormRules: {
        machine_name: [
          { required: true, message: '请输入设备名称', trigger: 'blur' }
        ],
        machine_brand_id: [
          { required: true, message: '请选择设备品牌', trigger: 'blur' }
        ],
        machine_model_id: [
          { required: true, message: '请选择设备型号', trigger: 'blur' }
        ],
        machine_serial: [
          { required: true, message: '请输入序列号', trigger: 'blur' }
        ]
      },
      loading: false,
      dialogMachineId: '', // 路牙机id
      showLookDeviceMachine: false // 路牙机弹框

    }
  },
  created () {
    if (this.queryStatus === 2) {
      this.queryForm = this.deepClone(this.queryObj)
    }
    this.fnGetDeviceMachineList()
  },
  beforeRouteLeave (to, from, next) {
    this.setQueryObj(this.queryForm)
    this.setQueryStatus(1)
    next()
  },
  mounted () {
    window.addEventListener('beforeunload', e => this.beforeunloadFn(e))
    this.parkingList = this.$store.state.parkingList
  },
  computed: {
    ...mapState(['queryObj', 'queryStatus']),
    ...mapState('menuList', ['isRoutW']),
    ...mapGetters(['getUserInfo', 'getAllParkingList'])
  },
  destroyed () {
    window.removeEventListener('beforeunload', e => this.beforeunloadFn(e))
  },
  methods: {
    ...mapMutations(['setQueryObj', 'setQueryStatus']),
    beforeunloadFn (e) {
      this.setQueryStatus(1)
    },
    async fnGetDeviceMachineList () {
      // console.log(this.queryForm);
      const res = await getDeviceMachineList({
        parking_id: this.queryForm.parking_id,
        PageSize: this.queryForm.PageSize,
        PageIndex: this.queryForm.PageIndex,
        device_name: this.queryForm.device_name,
        device_serial: this.queryForm.device_serial,
        device_state: this.queryForm.device_state ? this.queryForm.device_state : null
      })
      this.total = res.Data.TotalCount
      this.tableData = res.Data.DataList
      this.page.total = res && res.Code === 200 && res.Data ? res.Data.TotalCount : 0
    },
    // 查询
    onSubmit () {
      this.queryForm.PageIndex = 1
      // console.log(this.queryForm);
      this.fnGetDeviceMachineList()
    },
    // 每页条数改变
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`);
      this.queryForm.PageSize = val
      this.fnGetDeviceMachineList()
    },
    // // 每页条数改变
    // sizeChange (val) {
    //   this.page.currentPage = 1
    //   this.page.pageSize = val
    //   this.queryForm.PageSize = val
    //   this.fnGetDeviceMachineList()
    // },
    // 页码改变
    handleCurrentChange (val) {
      this.queryForm.PageIndex = val
      this.fnGetDeviceMachineList()
      this.$refs.multipleTable.bodyWrapper.scrollTop = 0

      // console.log(`当前页: ${val}`);
    },
    // // 页码改变
    // currentChange (val) {
    //   this.queryForm.PageIndex = val
    //   this.page.currentPage = val
    //   this.fnGetDeviceMachineList()
    // },
    // 当选择项发生变化时会触发该事件
    handleSelectionChange (val) {
      // console.log(val);
      this.multipleSelection = val
    },
    // // 选中的数据
    // selectionChange (list) {
    //   this.multipleSelection = list
    // },
    // 批量删除
    batchDel () {
      if (this.multipleSelection.length > 0) {
        this.$confirm('此操作将永久删除选中的路牙机, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          // this.$msg.success('删除成功!');
          var obj = {}
          var arr = []
          // this.$msg.success('删除成功!');
          for (var i = 0; i < this.multipleSelection.length; i++) {
            arr.push(this.multipleSelection[i]._id)
            if (arr.length === this.multipleSelection.length) {
              obj._ids = arr
              this.fnDelDeviceMachine(obj)
            }
          }
        }).catch(() => {
          this.$msg.info('已取消删除')
        })
      } else {
        this.$msg.warning('请选择要删除的路牙机！')
      }
    },
    // 删除设备请求
    async fnDelDeviceMachine (obj) {
      const res = await delDeviceMachine(obj)
      console.log(res)
      if (res && res.Code === 200) {
        this.$msg.success(res.Message)
      }
      this.fnGetDeviceMachineList()
    },
    // 新增设备
    openAddForm () {
      this.title = '新增设备'
      this.fnGetBrandList()
      this.isAddDialog = true
    },
    // 设备品牌下拉框
    async fnGetBrandList () {
      const res = await getBrandList({
        device_type: 4
      })
      if (res && res.Code === 200) {
        this.brandList = res.Data
      } else {
        this.brandList = []
      }
    },
    // 当设备品牌选中项发生改变时
    brandChange (val) {
      console.log('当设备品牌选中项发生改变时----', val)
      const arr = this.brandList.filter(item => {
        return item._id === val
      })
      console.log(arr)
      this.addForm.machine_brand_id = arr[0]._id
      this.addForm.machine_brand_name = arr[0].brand_name
      // 根据品牌id获取设备型号
      this.fnGetModelList(arr[0]._id)
      this.addForm.machine_model_id = ''
      this.addForm.machine_model_name = ''
      this.addForm.machine_model_pic = ''
    },
    // 设备型号下拉框
    async fnGetModelList (id) {
      const res = await getModelList({
        brand_id: id,
        device_type: 4
      })
      console.log('设备型号列表-----', res)
      if (res && res.Code === 200) {
        this.modelList = res.Data
      } else {
        this.modelList = []
      }
    },
    // 当设备型号下拉框发生改变时
    modelChange (val) {
      console.log('当设备型号下拉框发生改变时--', val)
      var arr = this.modelList.filter((item) => {
        return item._id === val
      })
      this.addForm.machine_model_name = arr[0].model_name
      this.addForm.machine_model_pic = arr[0].pic
    },
    // 修改设备
    openEditForm (row) {
      this.title = '编辑设备'
      this.fnGetBrandList()
      this.fnGetModelList(row.machine_brand_id)
      this.addForm._id = row._id
      this.addForm.machine_name = row.machine_name
      this.addForm.machine_brand_id = row.machine_brand_id
      this.addForm.machine_brand_name = row.machine_brand_name
      this.addForm.machine_model_id = row.machine_model_id
      this.addForm.machine_model_name = row.machine_model_name
      this.addForm.machine_serial = row.machine_serial
      this.addForm.machine_model_pic = row.machine_model_pic
      this.isAddDialog = true
    },
    // 关闭新增编辑对话框，重置数据
    closeAddDialogVisible () {
      this.isAddDialog = false
      // 移除校验结果
      this.$refs.addFormRef.resetFields()
      console.log(this.addForm)
      this.addForm._id = ''
      this.addForm.machine_name = ''
      this.addForm.machine_brand_id = ''
      this.addForm.machine_brand_name = ''
      this.addForm.machine_model_id = ''
      this.addForm.machine_model_name = ''
      this.addForm.machine_serial = ''
      this.addForm.machine_model_pic = ''
    },
    // 新增/编辑
    submitAddForm () {
      // 对整个表单进行校验
      this.$refs.addFormRef.validate((valid) => {
        // 成功
        if (valid) {
          var obj = {
            machine_name: this.addForm.machine_name,
            machine_brand_id: this.addForm.machine_brand_id,
            machine_brand_name: this.addForm.machine_brand_name,
            machine_model_id: this.addForm.machine_model_id,
            machine_model_name: this.addForm.machine_model_name,
            machine_serial: this.addForm.machine_serial,
            machine_model_pic: this.addForm.machine_model_pic
          }
          if (this.addForm._id) {
            // 编辑
            obj._id = this.addForm._id
            this.fnUpdateDeviceMachine(obj)
          } else {
            // 新增
            this.fnAddDeviceMachine(obj)
          }
        } else {
          return false
        }
      })
    },
    // 新增设备请求
    async fnAddDeviceMachine (obj) {
      await addDeviceMachine(obj)
      this.isAddDialog = false
      this.onSubmit()
      this.closeAddDialogVisible()
    },
    // 编辑设备请求
    async fnUpdateDeviceMachine (obj) {
      await updateDeviceMachine(obj)
      this.isAddDialog = false
      this.fnGetDeviceMachineList()
      this.closeAddDialogVisible()
    },
    lookDeviceMachine (row) {
      this.showLookDeviceMachine = true
      this.dialogMachineId = row._id
      // this.$router.push({
      //   path: '/look-device-machine',
      //   query: {
      //     machine_id: row._id
      //   }
      // })
    }
  }
}
</script>

<style scoped lang="less">
.ParkingInformation {
  margin-top: 20px;
}

.el-select {
  width: 100%;
}

.btnTempate {
  margin-bottom: 8px;
}

.dialog-video-preview {
  /deep/.el-dialog {
    background-color: rgba(0, 0, 0, 0) !important;

    .el-dialog__header {
      height: 0 !important;
      padding: 0 !important;

      .el-dialog__headerbtn {
        right: 5px !important;
        top: 5px !important;
        width: 30px !important;
        height: 30px !important;
        background-color: rgba(0, 0, 0, 0.8) !important;
        border-radius: 15px !important;
        overflow: hidden !important;
        z-index: 9999 !important;
      }
    }

    .el-dialog__body {
      height: 100%;
      padding: 0 !important;
    }
  }
}
</style>

<template>
    <!-- 路内停车场 ————> 查看车牌识别路牙机 -->
    <div class="box-card" style="text-align: left;margin-top:0;">
      <!-- 主要内容 -->
      <div class="content">
        <el-form label-position="right" label-width="120px" :model="detailsList" class="left">
          <!-- <div class="title">基本信息</div>
          <el-divider></el-divider> -->
          <el-form-item label="设备名称：">{{detailsList.machine_name}}</el-form-item>
          <el-form-item label="设备品牌：">{{detailsList.machine_brand_name}}</el-form-item>
          <el-form-item label="设备型号：">{{detailsList.machine_model_name}}</el-form-item>
          <!-- <div class="title" style="margin-top:100px;">硬件属性</div>
          <el-divider></el-divider> -->
          <el-form-item label="序列号：">{{detailsList.machine_serial}}</el-form-item>
          <!-- <el-form-item label="硬件版本：">{{detailsList.hardware_version}}</el-form-item>
          <el-form-item label="软件版本：">{{detailsList.software_version}}</el-form-item>
          <el-form-item label="模组编号：">{{detailsList.imei}}</el-form-item> -->
          <el-form-item label="电量：">
            <div :style="detailsList.electric_quantity <= 20 ?  'color: #ff0000;' : 'color: #18943b;'">{{ detailsList.electric_quantity}}%</div>
          </el-form-item>
          <el-form-item label="状态更新时间：">
            {{ $moment(detailsList.heartbeat_time).format("YYYY-MM-DD HH:mm:ss") }}
          </el-form-item>
        </el-form>
        <div class="right">
          <el-image style="width: 100%;" :src="detailsList.machine_model_pic" v-if="detailsList.machine_model_pic">
          </el-image>
          <div class="innerText">设备图片</div>
        </div>
      </div>
    </div>
  </template>
<script>
import { getDeviceMachineDetails } from '@/api/deviceApi'
import { mapMutations, mapState } from 'vuex'
export default {
  props: ['machine_id'],
  data () {
    return {
      detailsList: {}
    //   machine_id: null
    }
  },
  created () {
    // this.machine_id = this.$route.query.machine_id
    this.fnGetDeviceMachineDetails()
  },
  mounted () { },
  computed: {
    ...mapState('menuList', ['isRoutW'])
  },
  methods: {
    ...mapMutations(['setQueryStatus']),
    // 车牌识别路牙机详情
    async fnGetDeviceMachineDetails () {
      const res = await getDeviceMachineDetails({
        machine_id: this.machine_id
      })
      console.log('车牌识别路牙机详情----', res)
      this.detailsList = res.Data
    }
  }
}
</script>

  <style scoped lang="less">
  .content {
    width: 100%;
    padding:0 20px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: start;
  }
  .title {
    width: 100%;
    text-align: left;
    font-size: 18px;
    font-weight: 700;
    color: #000;
  }
  .content .left {
    width: 70%;
  }
  .left .el-form-item__content {
    text-align: left;
  }
  .left .el-row {
    width: 100%;
  }
  .left .el-form-item {
    margin-bottom: 0px;
  }
  .content .right {
    width: 40%;
    background-color: #FAFAFA;
    .innerText{
      width: 100%;
      text-align: center;
      color: #fff;
      background-color: #7D7D7D;
    }
  }
  </style>
